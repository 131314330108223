<template>
  <div>
    <div class="info1">
      <div class="top1">
        <div class="leftTop1">
          <van-nav-bar left-text="" left-arrow @click-left="onClickLeft"/>
        </div>
        <div class="rightTop1">
          <span class="title1">《热血大乱斗》账号注销</span>
        </div>
      </div>
      <div class="cont1">
        <p class="common">1.注销游戏帐号是不可恢复的操作。操作之前，请确认与游戏帐号相关的所有服务均已妥善处理，并务必审慎阅读、充分理解以下内容。如果您有任何疑问，可与游戏客服联系，我们会耐心地与您沟通、给予您必要的帮助。</p>
        <p class="common">2.成功注销后，您将无法继续使用该游戏帐号，或找回与您游戏帐号相关的任何内容和信息(即使使用相同帐号再次登录)，包括但不限于:</p>
        <p class="common">2-1.您将无法继续体验该游戏帐号</p>
        <p class="common">2-2.您的该帐号下的所有角色资料和相关增值服务，包括虚拟财产(包括但不限于已消耗及未消耗的虚拟货币、虚拟物品、游戏虚拟权益等) 都将无法再找回.</p>
        <p class="common">2-3我们将删除您游戏帐号的所有相关信息。</p>
        <p class="common">3.游戏号注销针对该帐号下所有区服角色。当您完成游戏帐号注销时，该帐号下的所有角色记录将无法找回，但不影响帐号在游戏平台的正常使用。</p>
        <p class="common">4.游戏帐号注销处理需要一定的时间。如果您希望撤销本次注销，可以在提交注销申请后30日内，通过联系客服撤销注销，系统将撤销游戏帐号注销申请并为您恢复游戏帐号数据。</p>
        <p class="common">5.在游戏帐号注销期间，如果您的游戏帐号被他人投诉、被国家机关调查或者正处于诉讼、仲裁程序中，我们有权自行终止您的注销行为而无需另行得到您的同意。</p>
        <p class="common">6.请注意，注销您的游戏帐号并不代表本游戏帐号注销前的任何行为和相关责任得到豁免或减轻。</p>
      </div>
    </div>
    <div class="checkStatus1">
      <input type="radio" value="true" v-model="checkedOption" @click="updateSelectedOption">
      <span>我已阅读并了解注销条件</span>
    </div>
    <van-form @submit="onSubmit" style="margin-left: 0.2rem;margin-top: -0.7rem">
      <van-button style="width: 94.5%;border-color: #02A7F0;background-color: #02A7F0" type="danger">下一步</van-button>
    </van-form>
  </div>
</template>

<script>
import { Toast } from "vant";
import Vue from 'vue';
import { NavBar } from 'vant';
Vue.use(NavBar);
export default {
  data() {
    return {
      checkedOption: false
    };
  },
  methods: {
    onClickLeft() {
      //  同意后跳转到删除账号页面
      this.$router.push({
        path: '/deluser'
      });
    },
    updateSelectedOption() {
      this.checkedOption = this.checkedOption === false ? true : false
    },
    onSubmit() {
      if (this.checkedOption != 'true') {
        Toast.fail('请选择已阅读')
        return
      }
      //  同意后跳转到删除账号页面
      this.$router.push({
        path: '/deluser/rxdld/login'
      });
    }
  },
};
</script>

<style>

.info1{
  margin: 0.2rem 0.2rem;
  border: 1px solid #333;
}
.top1{
  font-size: 0.2rem;
  font-weight: bold;
  top: 10%;
  text-align: center;
  border-bottom: 2px solid #333;
  width: 100%;
  height: 0.4rem;
}

.leftTop1{
  width: 10%;
  display: inline-block;
  font-size: large;
}
.rightTop1{
  width: 90%;
  display: inline-block;
  margin-top: 0.1rem;
}

.title1{
  font-weight: bold;
  font-size: 15px;
}

.cont1{
  font-size: 0.15rem;
}

.checkStatus1{
  position: relative;
  margin-top: -0.15rem;
  margin-left: 0.15rem;
  font-size: 0.15rem;
  margin-bottom: 0.05rem;
}
.van-nav-bar__left, .van-nav-bar__right {
  position: absolute;
  top: -13px;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 16px;
  font-size: 10rem;
  cursor: pointer;
}
.van-nav-bar .van-icon {
  color: black;
  font-size: x-large;
}
.van-nav-bar__content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
}
</style>
